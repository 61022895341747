// @ts-nocheck
import { useBlogCategoriesQuery } from '@/kytesoft-client/features/blogCategories';
import { useProductCategoriesQuery } from '@/kytesoft-client/features/productCategories';
import { Link } from '@components/primitive/Link';
import mainMenuStyles from '@scss/layout/main-menu.scss';
import { routes } from '@utils/routes';
import { get, isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

function MainMenu() {
  const { pathname } = useRouter();

  const { data: categories }: any = useProductCategoriesQuery({
    queryKey: {
      location: 'menu-products',
    },
  });
  const { data: blogCategories }: any = useBlogCategoriesQuery({
    queryKey: {
      location: 'main-menu',
      usedFor: 'blogs',
    },
  });

  const MENU_LIST: any = useMemo(
    () => [
      {
        label: 'Trang chủ',
        route: routes.HOME,
      },
      {
        label: 'Giới thiệu',
        route: routes.ABOUT,
      },
      {
        label: 'Sản phẩm',
        route: routes.PRODUCTS,
        submenu: map(categories, (category: any) => ({
          label: category?.name,
          route: {
            pathname: routes.PRODUCTS,
            query: {
              category: category?.slug,
            },
          },
        })),
      },
      {
        label: 'Tin tức',
        route: routes.BLOGS,
        submenu: map(get(blogCategories, 'items', []), (category: any) => ({
          label: category?.name,
          route: {
            pathname: routes.BLOGS,
            query: {
              category: category?.slug,
            },
          },
        })),
      },
      {
        label: 'Liên hệ',
        route: routes.CONTACT,
      },
      {
        label: 'Hệ thống phân phối',
        route: routes.DISTRIBUTION,
      },
    ],
    [blogCategories, categories],
  );

  return (
    <>
      <style jsx>{mainMenuStyles}</style>
      <nav className="ks-main-menu">
        <div className="ks-menu-wrapper">
          <ul className="menu">
            {map(MENU_LIST, (item) => (
              <li className="item" key={item.label}>
                <Link
                  className={classNames('link', { '-active': pathname === item.route })}
                  title={item.label}
                  href={item.route}
                >
                  {item.label}
                  {!isEmpty(item.submenu) && (
                    <span className="chevron">
                      <i className="fa-thin fa-chevron-down fa-sm icon" />
                    </span>
                  )}
                </Link>
                <ul className="ks-main-menu-children">
                  {map(item.submenu, (subItem) => (
                    <li className="item" key={subItem.label}>
                      <div className="wrapper">
                        <Link className="link" title={subItem.label} href={subItem.route}>
                          {subItem.label}
                        </Link>
                        {!isEmpty(subItem?.submenu) && (
                          <span className="chevron">
                            <i className="fa-solid fa-chevron-right fa-xs" />
                          </span>
                        )}
                      </div>
                      <ul className="child">
                        {!isEmpty(subItem?.submenu) &&
                          map(subItem?.submenu, (child) => (
                            <li className="item" key={child.label}>
                              <Link className="link" title={child.label} href={child.route}>
                                {child.label}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default MainMenu;
